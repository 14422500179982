import React from 'react';

/* Images */
import favoriteIconSvg from 'org/assets/images/icons/favorite.inline.svg';
import hiwClickImage from 'org/assets/images/hiw/hiw_home_click.png';
import hiwEarnImage from 'org/assets/images/hiw/hiw_home_earn.png';
import hiwExploreImage from 'org/assets/images/hiw/hiw_home_explore.png';
import hiwShopImage from 'org/assets/images/hiw/hiw_home_shop.png';
import checkmarkSVG from 'org/assets/images/onboarding/delta_checkmark.svg';
import homeButtonImage from 'org/assets/images/home_button.png';

/* Components */
import ButtonScrollingModalContent
  from 'org/modules/Modal/components/ButtonScrollingModal/components/ButtonScrollingModalContent';

export const favoritesIcon = {
  defaults: {
    favoriteIconViewBox: '0 -2 30 30',
    FavoriteIconSvg: favoriteIconSvg,
  },
};

export const creditCardMarketingBanner = {
  defaults: {
    shouldShowCardTerms: true,
    getShowCardTip: () => (true),
    shouldShowCardHeading: true,
  },
};

export const searchBar = {
  defaults: {
    getPlaceholder: () => ('Find a Store or Product'),
  },
};

export const searchForm = {
  defaults: {
    searchButtonClassName: 'mn_deltaSVGIconSearchGrey',
  },
};

export const searchModal = {
  defaults: {
    placeholderLanguage: '',
  },
};

export const headerExtraRewards = {
  defaults: {
    viewAllLanguage: 'See All Stores',
    getTitle: (earnType) => `Stores Offering Extra ${earnType}`,
  },
};

export const recentStores = {
  defaults: {
    viewData: {
      footerCTATitle: 'View All Recent Stores',
    },
    rebateOptions: {
      showAdditionalElevationPrefix: false,
      hideRebatePrefix: true,
      elevation: false,
      tiered: {
        showAdditionalElevationPrefix: false,
        hideRebatePrefix: true,
      },
    },
  },
};

export const similarStores = {
  defaults: {
    rebateOptions: {
      showAdditionalElevationPrefix: false,
      hideRebatePrefix: true,
      elevation: false,
      tiered: {
        showAdditionalElevationPrefix: false,
        hideRebatePrefix: true,
      },
    },
  },
};

export const quickSearch = {
  defaults: {
    rebateOptions: {
      showAdditionalElevationPrefix: false,
      hideRebatePrefix: true,
      elevation: false,
      tiered: {
        showAdditionalElevationPrefix: false,
        hideRebatePrefix: true,
      },
    },
  },
};

export const navCategoryFeatured = {
  defaults: {
    rebateOptions: {
      showRebateInline: true,
      hideElevationCurrency: false,
    },
  },
};

export const navCategoryFlyout = {
  defaults: {
    shouldShowMerchantName: true,
    rebateOptions: {
      showRebateInline: true,
    },
  },
};

export const navCategoriesTOF = {
  defaults: {
    secTitle: 'Shop by Category',
  },
};

export const trendingAndSeasonalOffers = {
  defaults: {
    getTitle: () => 'More Ways To Earn Miles',
    getMotto: () => 'Check out',
  },
};

export const emailOptInSubscribeModal = {
  defaults: {
    imageBeforeHeader: true,
    earnType: 'Miles',
    dialogText: 'Sign up to receive emails and be in the know.',
    submitButtonText: 'Sign up for email',
    cancelText: 'No thanks, I don\'t like earning extra miles.',
    emailOptions: {
      email_news_optin: 1,
      email_odotw_optin: 1,
      email_promo_optin: 1,
      email_global_optout: 0,
    },
  },
};

export const emailOptInHome = {
  defaults: {
    form: 'EmailPopup',
  },
};

export const onboardingWelcome = {
  defaults: {
    getWelcomeText: (storeCount) => (
      <>Here are a few tips for earning the most miles <span>when you shop online at {storeCount}+ stores.</span></>
    ),
  },
};

export const onboardingEmail = {
  defaults: {
    getOptInText: (earnType) => (
      <>Don't hunt for sales or extra {earnType} offers. <br /> We'll email them to you.</>
    ),
  },
};

export const onboardingFavorites = {
  defaults: {
    slick: {
      infinite: false,
    },
  },
};

export const onboardingButton = {
  defaults: {
    viewData: {
      getButtonHeader: () => (
        <>Earn Miles With The Push Of A Button</>
      ),
      getButtonDescription: () => (
        <>Get the new SkyMiles<sup>&reg;</sup> Shopping button and never miss out on miles.</>
      ),
      usePeriod: true,
    },
  },
};

export const onboardingMobileCongratulation = {
  defaults: {
    image: checkmarkSVG,
    getCongratsText: (earnType) => (`You're all set to start shopping and earning ${earnType}.`),
  },
};

export const headerOnboardingCongratulations = {
  defaults: {
    image: checkmarkSVG,
    getCongratulationText: (earnType) => (`You're all set to start shopping and earning ${earnType}.`),
  },
};

export const merchantOffer = {
  defaults: {
    getCopyButtonText: (isMobileMQ) => (
      isMobileMQ ? 'Copy' : 'Copy code'
    ),
    getExpiresText: (isMobileMQ) => (
      isMobileMQ ? 'Valid thru ' : 'Valid through '
    ),
    getCopyButtonDisclaimerText: (isMobileMQ) => (
      isMobileMQ ? 'Copy and paste this code at checkout.' : 'Copy and paste this code and use at checkout.'
    ),
    dateFormat: 'MM/DD/YY',
    rebateOptions: {
      hideElevationCurrency: false,
      hideRebateCurrencyPrefixInWas: true,
    },
  },
};

export const popularOffers = {
  defaults: {
    rebateOptions: {
      additionalRebatePrefix: 'Plus',
      tiered: {
        additionalRebatePrefix: 'Plus',
      },
    },
  },
  h: {
    slick: {
      dots: true,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            dots: false,
            slidesToShow: 1,
          },
        },
      ],
    },
  },
};

export const bonusOffers = {
  defaults: {
    rebateOptions: {
      additionalRebatePrefix: 'Plus',
      tiered: {
        additionalRebatePrefix: 'Plus',
      },
    },
  },
};

export const howItWorks = {
  defaults: {
    hiwItems: [
      {
        image: hiwExploreImage,
        title: 'Explore',
        description: ({ storeCount }) => (
          <>Explore over {storeCount} stores, exclusive offers and bonus promotions, all in one place.</>
        ),
      },
      {
        image: hiwClickImage,
        title: 'Click',
        description: () => (
          <>Click on any store or offer and you'll be taken to the store's website.</>
        ),
      },
      {
        image: hiwShopImage,
        title: 'Shop',
        description: () => (
          <>Shop like you normally do and pay with any credit card — no special card needed.</>
        ),
      },
      {
        image: hiwEarnImage,
        title: 'Earn',
        description: () => (
          <>After making a purchase, miles earned will be added to your SkyMiles account.</>
        ),
      },
    ],
    getVideoLinkText: (userIsIdentified) => (userIsIdentified ? 'Watch how it works' : 'Learn More'),
    checkShouldShowVideoLink: () => (true),
    getSectionTitleText: (userIsIdentified) => (
      userIsIdentified
      ? 'How It works'
      : 'Sign Up for Free to Start Earning Miles Today'
    ),
    checkShouldUseCarousel: (userIsIdentified) => (userIsIdentified),
  },
};

export const featuredDeals = {
  defaults: {
    getViewAllLinkText: (isMobileMQ) => (
      isMobileMQ ? 'See All Deals' : 'View All Deals'
    ),
    getMotto: (isMobileMQ) => (isMobileMQ ? 'Check out' : ''),
  },
};

export const personalizedTopOffers = {
  defaults: {
    sectionTitle: 'These Great Offers',
    getMotto: () => 'Earn with',
  },
};

export const testimonials = {
  defaults: {
    testimonialsCopy: [
      'Having the ability to earn double or triple the amount of SkyMiles for my purchase is very enticing ' +
      'and has pushed me to make purchases sooner than anticipated so I earned the miles.',
      'I really like using SkyMiles shopping. I enjoy getting extra miles for buying things I need to buy anyway. ' +
      'The shopping button makes it soooo much easier!!',
      'SkyMiles is so convenient to use and the rewards are awesome! ' +
      'Great all around experience time after time, after time! :-)',
    ],
    checkShouldShowIconInCarousel: (activeMQ) => (['XS', 'S'].includes(activeMQ)),
    checkShouldShowIconInSlide: (activeMQ) => (['M', 'L', 'XL', 'XXL'].includes(activeMQ)),
    checkShouldUseCarousel: (userIsIdentified) => (!userIsIdentified),
    slick: {
      slidesToShow: 1,
      centerMode: true,
      arrows: true,
      dots: true,
      autoplay: true,
      centerPadding: '0px',
      responsive: [
        {
          breakpoint: 769,
          settings: {
            arrows: false,
          },
        },
      ],
    },
  },
};

export const infoBar = {
  defaults: {
    infoBarAccordion: {
      couponCodeLanguage: 'Shop with code: ',
    },
    dealOfTheWeek: {
      dateFormat: 'MM/DD/YYYY',
      rebateOptions: {
        hideElevationCurrency: true,
        tiered: {
          hideElevationCurrency: true,
        },
      },
    },
    salesInfoBar: {
      dateFormat: 'MM/DD/YYYY',
      rebateOptions: {
        hideElevationCurrency: true,
        tiered: {
          hideElevationCurrency: true,
        },
      },
    },
  },
};

export const earningsSnapshot = {
  defaults: {
    ctaText: 'Go to my account',
  },
};

export const recentStoresFlyout = {
  defaults: {
    headingText: 'Quick links',
  },
};

export const topStores = {
  defaults: {
    useCarouselOnMediaQueriesOnly: ['M', 'L', 'XL', 'XXL'],
  },
};

export const adBlockWarning = {
  defaults: {
    getWarningMessage: () => (
      `The use of ad blockers may prevent you from earning SkyMiles&reg; Shopping miles on your shopping.
      While shopping with SkyMiles&reg; Shopping, turn off any ad blockers or use a different browser
      to ensure you earn miles as expected.`
    ),
  },
};

export const letterboxTOF = {
  defaults: {
    getCouponCodeLabelText: (isMobileMQ) => (
      isMobileMQ ? 'Use code: ' : 'Shop with code: '
    ),
  },
};

export const merchantLogos = {
  h: {
    apiParams: {
      limit: 8,
    },
    slick: {
      responsive: [
        {
          breakpoint: 415,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 5,
          },
        },
      ],
    },
    useCarouselOnMediaQueriesOnly: ['XS', 'S'],
  },
};

export const homeButtonBenefits = {
  defaults: {
    buttonHeader: 'Earn Miles With the Push of a Button',
    buttonDescription: `Add the button browser extension for Chrome and you'll
    get notifications while shopping so you never forget to earn miles. Plus, you can:`,
    buttonCopy: [
      'Automatically apply coupons at checkout',
      'Find new stores offering miles per dollar',
      'Get the lowest price with comparison features',
    ],
    buttonImage: homeButtonImage,
  },
};

export const featuredOffersLogo = {
  defaults: {
    getTitle: (storeCount) => <>Earn at {storeCount}+ Stores</>,
  },
};

export const stickyCtaBar = {
  defaults: {
    topOffsetSelector: '.mn_primaryHeroWrap',
    useStickyBehaviorOnMediaQueriesOnly: ['XL', 'XXL'],
    viewOptions: {
      title: <>Turn your Shopping into More Miles Today</>,
      buttonLanguage: 'Sign up for free',
    },
    useRegistrationLink: true,
  },
};

export const firstFavoriteModal = {
  defaults: {
    titleText: 'SUCCESS.',
    getBodyText: (siteUrl) => (
      <>
        Keep picking your favorite stores and we'll email you when they offer extra miles.
        Manage alert preferences at
        {' '}
        <a className="mn_myAccountLink" href={`${siteUrl}/ai____.htm`}>MY ACCOUNT</a>.
      </>
    ),
    buttonText: 'OKAY',
  },
};

export const navBanner = {
  defaults: {
    bannerText: {
      favorites: {
        title: 'Pick favorites',
        text: () => <>Pick your favorite stores and get email alerts about extra miles. </>,
        ctaTitle: 'Pick Favorites',
      },
    },
  },
};

export const buttonScrollingModal = {
  defaults: {
    components: {
      ButtonScrollingModalContent,
    },
  },
};
